import React, { Component } from 'react';

import { LazyLoadImage }
from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import 'react-photoswipe/lib/photoswipe.css';
import {PhotoSwipe} from 'react-photoswipe';

class ImageText extends Component {

  constructor() {
    super();
    this.state = {
      data: [],
      brand: false,
      full_width: false,
      gallery: [],
      galleryOpen: false,
      zoomImages: false,
      x: 0,
      y: 0,
      attachmentClass: 'btn-download-float',
    }
    this.hoverVideo = React.createRef();
  }

  componentDidMount() {

    const urlParams = new URLSearchParams(window.location.search);

    if (this.isMobileView()) {
      this.props.data.text_pos = this.props.imageVideoTextPosition;
    }

    this.setState({
      data: this.props.data,
      brand: this.props.brand,
      full_width: this.props.fullWidth,
      layout: this.props.layout,
      zoomImages: this.props.zoomImages === 1
    });

    if(
        urlParams.get('p') &&
        urlParams.get('m')*1 === this.props.data.content_module_id &&
        urlParams.get('p')*1 === this.props.data.id
    ) {
      let self = this;

      setTimeout(function(){
        self.showModal(self.state.data);
      },1000);
    }

    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  isMobileView() {
    return window.innerWidth <= 768;
  }

  handleScroll = (event) => {
    this.setState({ attachmentClass: 'btn-download-float' });
  }

  imageHasDownloads = (item) => {
    return item.attachments && item.attachments.length > 0;
  }

  showModal = (item) => {
    if(!this.imageHasDownloads(item)) return false;
    this.setState({ attachmentClass: 'btn-download-float' });
    this.props.openModal(item);
  }

  openPhotoSwipe = (image) => {
    if(!this.state.zoomImages) return false;
    this.setState({
      galleryOpen: true,
      gallery: [{src:image.image_1600, w:image.w ? image.w : 1500, h: image.h ? image.h : 1500}],
    });
  };

  handleClose = () => {
    this.setState({
      galleryOpen: false,
      gallery: []
    });
  };

  formatUrl = () => {
    let url = this.state.data.url;

    if (!url) return false;

    if (!isNaN(url) || url.charAt(0) === '+') {
      return 'tel:'+url;
    }

    if (url.indexOf('mailto:') === -1 && url.indexOf('http://') === -1 && url.indexOf('https://') === -1 && url.indexOf('@') !== -1) {
      return 'mailto:'+url;
    }

    if (url.indexOf('http://') === -1 && url.indexOf('https://') === -1 && url.indexOf('mailto:') === -1 && url.indexOf('anchor:') === -1 && url.indexOf('page:') === -1) {
      return 'https://'+url;
    }

    return url;
  }

  rewriteUrls = (text) => {
    if (text) {
      if (!this.state.brand.has_master) {
        return text;
      };

      const linkToReplace = 'https://my.corebook.io/' + this.state.brand.master_links.link;
      const linkToReplaceWith = this.state.brand.domain ? 'https://' + this.state.brand.domain : 'https://my.corebook.io/ ' + this.state.brand.link;

      var urlRegex = /(https?:\/\/[^\s]+)/g;
      return text.replace(urlRegex, function(url) {
        return url.replace(linkToReplace, linkToReplaceWith);
      })
    }
  }

  _onMouseEnter(e) {
    this.setState({ attachmentClass: 'btn-download-float visible' });
    if (this.hoverVideo.current) {
      this.hoverVideo.current.currentTime = 0;
    }
  }

  _onMouseMove(e) {
    this.setState({
      x: e.clientX + 5,
      y: e.clientY - 30,
      attachmentClass: 'btn-download-float visible'
    });
  }

  _onMouseLeave(e) {
    this.setState({ attachmentClass: 'btn-download-float' });
  }

  render () {
    const { galleryOpen, gallery } = this.state;

    return (
      <div className="module_image" style={{'position':'relative', 'maxWidth': '100%', 'width':(this.state.full_width ? '100%' : (this.state.data.text_width > this.state.data.width ? this.state.data.text_width : this.state.data.width)+'px'), 'left':(this.state.full_width ? 0 : this.state.data.left)+'px', 'marginTop':(this.state.full_width ? 0 : this.state.data.top)+'px'}}>
        {this.state.data.image && this.state.data.text && this.state.data.text_pos === 'top' && (
          <div className="dynamic-content" style={{width: this.state.data.text_width, padding: '0 0 10px', maxWidth: '100%'}}>
            <div className="text">
              <div className="editor-content" dangerouslySetInnerHTML={{__html: this.rewriteUrls(this.state.data.text)}}></div>
            </div>
          </div>
        )}

        <div className="dynamic-content" style={{'width':(this.state.full_width ? '100%' : this.state.data.width+'px')}}>
          <div className="module_image__image no-border" onMouseEnter={this._onMouseEnter.bind(this)} onMouseMove={this._onMouseMove.bind(this)} onMouseLeave={this._onMouseLeave.bind(this)}>
            {this.state.data.image && (
              <div className={this.state.data.hover ? 'image with--hover' : 'image'}>
                {!this.state.data.url && (
                  <div style={{cursor: this.imageHasDownloads(this.state.data) ? 'pointer' : (this.state.zoomImages ? 'zoom-in' : 'default')}} onClick={(e) => { e.preventDefault(); this.imageHasDownloads(this.state.data) ? this.showModal(this.state.data) : this.openPhotoSwipe(this.state.data) }}>
                    <div className="hover-default">
                      <div>
                        <img
                          alt=""
                          src={this.state.layout !== 'one' ? this.state.data.image_800 : (this.state.full_width ? this.state.data.image : this.state.data.image_1600)}
                          style={{ borderRadius:this.state.data.border_radius, border:this.state.data.border_width+'px solid '+this.state.data.border_color }}
                        />
                      </div>
                    </div>

                    {this.state.data.hover &&
                      <div className="hover-hover">
                        {this.state.data.hover_type === 'image' &&
                          <img src={this.state.layout !== 'one' ? this.state.data.hover_800 : (this.state.full_width ? this.state.data.hover : this.state.data.hover_1600)} style={{ borderRadius:this.state.data.border_radius, border:this.state.data.border_width+'px solid '+this.state.data.border_color }} alt=""/>
                        }

                        {this.state.data.hover_type === 'video' &&
                          <video ref={this.hoverVideo} autoPlay loop muted playsInline style={{ borderRadius:this.state.data.border_radius, border:this.state.data.border_width+'px solid '+this.state.data.border_color }}>
                            <source src={this.state.data.hover} type="video/mp4"/>
                            Your browser does not support the video tag.
                          </video>
                        }
                      </div>
                    }
                  </div>
                )}

                {this.state.data.url && (
                  <div>
                    <div className="hover-default">
                      <div style={{cursor: 'pointer'}}>
                        <a href={this.formatUrl()} target={this.state.data.target} rel="noopener noreferrer">
                          <img
                            alt=""
                            src={this.state.layout !== 'one' ? this.state.data.image_800 : (this.state.full_width ? this.state.data.image : this.state.data.image_1600)}
                            style={{ borderRadius:this.state.data.border_radius, border:this.state.data.border_width+'px solid '+this.state.data.border_color }}
                          />
                        </a>
                      </div>
                    </div>

                    {this.state.data.hover &&
                      <div className="hover-hover">
                        <div style={{cursor: 'pointer'}}>
                          <a href={this.formatUrl()} target={this.state.data.target} rel="noopener noreferrer">
                            {this.state.data.hover_type === 'image' &&
                              <img src={this.state.layout !== 'one' ? this.state.data.hover_800 : (this.state.full_width ? this.state.data.hover : this.state.data.hover_1600)} style={{ borderRadius:this.state.data.border_radius, border:this.state.data.border_width+'px solid '+this.state.data.border_color }} alt=""/>
                            }

                            {this.state.data.hover_type === 'video' &&
                              <video ref={this.hoverVideo} autoPlay loop muted playsInline style={{ borderRadius:this.state.data.border_radius, border:this.state.data.border_width+'px solid '+this.state.data.border_color }}>
                                <source src={this.state.data.hover} type="video/mp4"/>
                                Your browser does not support the video tag.
                              </video>
                            }
                          </a>
                        </div>
                      </div>
                    }
                  </div>
                )}

                {this.imageHasDownloads(this.state.data) && (
                  <div className={this.state.attachmentClass} style={{left: this.state.x, top:this.state.y }} onClick={(e) => { e.preventDefault(); this.showModal(this.state.data) }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><defs><path id="wee3a" d="M1468 2620v4a2 2 0 0 1-2 2h-14a2 2 0 0 1-2-2v-4"/><path id="wee3b" d="M1454 2615l5 5 5-5"/><path id="wee3c" d="M1459 2620v-12"/></defs><g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3a"/></g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3b"/></g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3c"/></g></g></svg>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <PhotoSwipe
          isOpen={galleryOpen}
          items={gallery}
          options={{
            captionEl: false,
            shareEl: false,
            closeOnScroll: true,
            zoomEl: false,
            history: false,
            arrowKeys: false
          }}
          onClose={this.handleClose}
        />

        {this.state.data.image && this.state.data.text && this.state.data.text_pos === 'bottom' && (
          <div className="dynamic-content" style={{width: this.state.data.text_width, padding: '10px 0 0', maxWidth: '100%'}}>
            <div className="text">
              <div className="editor-content" dangerouslySetInnerHTML={{__html: this.rewriteUrls(this.state.data.text)}}></div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ImageText;
