import React, { Component } from 'react';
import Cookies from "js-cookie";

import './CookieConsent.css';

class CookieConsent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      brand: false,
      allowAllCookies: false,
      cookieConsentPopup: true,
    };
    this.acceptAllCookies = this.acceptAllCookies.bind(this);
    this.rejectCookies = this.rejectCookies.bind(this);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if(newProps.brand) {
      this.setState({
        brand: newProps.brand,
        cookieConsentPopup: newProps.status,
      });
    }
  }

  acceptAllCookies() {
    Cookies.set('CookiesAccepted', 1);
    window.location.reload();
  }

  rejectCookies() {
    let mustReload = Cookies.get('CookiesAccepted') === 1;

    Cookies.set('CookiesAccepted', 0);

    this.setState({
      cookieConsentPopup: false
    });

    // Reload or update parent component popup status
    if (mustReload) {
      window.location.reload();
    } else {
      this.props.closeCookiePopup();
    }
  }

  // Render layout
  render () {
    return (
      <div className="cookie-consent" style={{ 
        backgroundColor: this.state.brand.main_background_color,
        display: this.state.cookieConsentPopup ? 'block' : 'none'
      }}>
        <div className="cookie-consent__text">
          <p style={{ 
            color: this.state.brand.text_color, 
            lineHeight: this.state.brand.body_line_height, 
            letterSpacing: this.state.brand.body_letter_spacing,
            fontFamily: this.state.brand.body_font
          }}
          dangerouslySetInnerHTML={{__html: this.state.brand.cc_text}}>
          </p>
        </div>
 
        <div className="cookie-consent__buttons">
          <button 
            className="accept" 
            style={{ 
              fontFamily: this.state.brand.body_font, 
              backgroundColor: this.state.brand.highlights_color, 
              borderColor: this.state.brand.highlights_color, 
              color: this.state.brand.highlights_text_color 
            }} 
            onClick={this.acceptAllCookies}>{this.state.brand.cc_accept}
          </button>

          <button 
            className="reject" 
            style={{ 
              fontFamily: this.state.brand.body_font, 
              color: this.state.brand.text_color, 
              border: '1px solid ' + this.state.brand.text_color + '55' }} 
              onClick={this.rejectCookies}>{this.state.brand.cc_reject}
          </button>
        </div>

        <div 
          className="cookie-consent__close" 
          style={{ backgroundColor: this.state.brand.highlights_color }} 
          onClick={this.rejectCookies}>
        </div>
      </div>
    );
  }
}

export default CookieConsent;
