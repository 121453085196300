import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import './ShareLocation.css';

import {localAnalyticsLogEvent} from 'localAnalytics';

class ShareLocation extends Component {
  constructor() {
    super();
    this.state = {
      url: '',
      copied: false,
    }
  }

  // Check which block is expanded by default
  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.module && !nextProps.popup){
      this.setState({
        url: window.location.href.split('?')[0] + (nextProps.first ? '' : '?m=' + nextProps.module)
      });
    }

    if(nextProps.module && nextProps.popup){
      this.setState({
        url: window.location.href.split('?')[0] + '?m=' + nextProps.module + '&p=' + nextProps.popup
      });
    }
  }

  onCopy = () => {
    this.setState({copied: true});
    localAnalyticsLogEvent("public_share_link_copied", {url: this.state.url, brandId: window.loadedBrandId});
  };

  resetCopy = () => {
    this.setState({copied: false});
  };

  render () {
    return (
      <div>
        <div className={this.state.copied ? 'copy-link copied' : 'copy-link'}>
            <svg className="icon-copy" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path fill="#303030" d="M25,28H11a3,3,0,0,1-3-3V11a3,3,0,0,1,3-3H25a3,3,0,0,1,3,3V25A3,3,0,0,1,25,28ZM11,10a1,1,0,0,0-1,1V25a1,1,0,0,0,1,1H25a1,1,0,0,0,1-1V11a1,1,0,0,0-1-1Z" transform="translate(-4 -4)"/>
                <path fill="#303030" d="M9,24H7a3,3,0,0,1-3-3V7A3,3,0,0,1,7,4H21a3,3,0,0,1,3,3V9a1,1,0,0,1-2,0V7a1,1,0,0,0-1-1H7A1,1,0,0,0,6,7V21a1,1,0,0,0,1,1H9a1,1,0,0,1,0,2Z" transform="translate(-4 -4)"/>
                <path fill="#303030" d="M15,24a3,3,0,0,1-2.12-5.12l1-1a3.08,3.08,0,0,1,4.24,0,3,3,0,0,1,0,4.24l-1,1A3,3,0,0,1,15,24Zm1-5a1,1,0,0,0-.71.29h0l-1,1A1,1,0,0,0,15.7,21.7l1-1A1,1,0,0,0,16,19Zm-1.41-.41Z" transform="translate(-4 -4)"/>
                <path fill="#303030" d="M20,19a3,3,0,0,1-2.12-5.12l1-1a3.08,3.08,0,0,1,4.24,0,3,3,0,0,1,0,4.24l-1,1A3,3,0,0,1,20,19Zm1-5a1,1,0,0,0-.71.29h0l-1,1A1,1,0,1,0,20.7,16.7l1-1A1,1,0,0,0,21,14Zm-1.41-.41Z" transform="translate(-4 -4)"/>
                <path fill="#303030" d="M17,20a1,1,0,0,1-1-1,1,1,0,0,1,.3-.72l2-2A1,1,0,0,1,19.7,17.7l-2,2A1,1,0,0,1,17,20Z" transform="translate(-4 -4)"/>
            </svg>

            <svg className="icon-copied" xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15">
              <defs>
                <path id="b3cia" d="M1095 702l-11 11-5-5"/>
              </defs>
              <g>
                <g transform="translate(-1077 -700)">
                  <use fill="#fff" fillOpacity="0" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" strokeWidth="3" xlinkHref="#b3cia"/>
                </g>
              </g>
            </svg>

            <CopyToClipboard onCopy={this.onCopy} text={this.state.url}>
              <span onMouseLeave={this.resetCopy}></span>
            </CopyToClipboard>
        </div>
      </div>
    );
  }
}

export default withRouter(ShareLocation);
