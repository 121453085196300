import React, { Component } from 'react';

class Text extends Component {

  constructor() {
    super();
    this.state = {
      data: [],
      brand: false,
      full_width: false
    }
  }

  componentDidMount() {
    this.setState({
      data: this.props.data,
      brand: this.props.brand,
      full_width: this.props.fullWidth
    });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.state.brand.link !== newProps.brand.link) {
      this.setState({
        brand: newProps.brand,
      });
    }
  }

  rewriteUrls = (text) => {
    if (text) {
      if (!this.state.brand.has_master) {
        return text;
      };

      const linkToReplace = 'https://my.corebook.io/' + this.state.brand.master_links.link;
      const linkToReplaceWith = this.state.brand.domain ? 'https://' + this.state.brand.domain : 'https://my.corebook.io/ ' + this.state.brand.link;

      var urlRegex = /(https?:\/\/[^\s]+)/g;
      return text.replace(urlRegex, function(url) {
        return url.replace(linkToReplace, linkToReplaceWith);
      })
    }
  }

  render () {
    return (
      <div className="module_paragraph_column">
          <div className="dynamic-content" style={{'width':(this.state.full_width ? '100%' : this.state.data.width+'px'), 'left':(this.state.full_width ? 0 : this.state.data.left)+'px', 'marginTop':(this.state.full_width ? 0 : this.state.data.top)+'px'}}>
              <div className="text">
                  <div className="editor-content" dangerouslySetInnerHTML={{__html: this.rewriteUrls(this.state.data.text_1)}}></div>
              </div>
          </div>
      </div>
    );
  }
}

export default Text;
