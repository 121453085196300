import React, { Component } from 'react';
import axios from "axios";
import './Auth.css';

const editors = ["admin", "owner", "editor"];

class Auth extends Component {

  constructor() {
    super();
    this.state = {
      brand           : false,
      page            : false,
      loggedIn        : false,
      showLogin       : false,
      userType        : false,
      userImage       : 'https://assets.corebook.io/users/avatar.png',
      showDropdown    : false,
      showLoginModal  : false,
      email           : '',
      password        : '',
      token           : false,
      emailValid      : false,
      showError       : false,
      errorText       : 'Enter valid email and password!',
      validToken      : false,
      emailFocus      : false,
      passwordFocus   : false,
      screen          : 'email',
    }

    this.wrapperRef         = React.createRef();
    this.loginFormRef       = React.createRef();
    this.logOut             = this.logOut.bind(this);
    this.toggleLogin        = this.toggleLogin.bind(this);
    this.toggleDropdown     = this.toggleDropdown.bind(this);
    this.closeDropdown      = this.closeDropdown.bind(this);
    this.handleScroll       = this.handleScroll.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleChange       = this.handleChange.bind(this);
    this.handleSubmit       = this.handleSubmit.bind(this);
  }

  toggleLogin = () => {
    this.setState({
      showLoginModal: this.state.showLoginModal ? false : true
    });
  }

  toggleDropdown = () => {
    this.setState({
      showDropdown: true
    });
  }

  closeDropdown = () => {
    this.setState({
      showDropdown: false
    });
  }

  logOut = () => {
    axios.post(window.API + 'logout', {},{
      headers: {
        Pragma: 'no-cache',
        Authorization: localStorage.getItem('authToken') || ''
      }
    }).then(function (response) {}).catch(error => {});

    localStorage.removeItem('authToken');
    localStorage.removeItem('authUser');

    window.location.reload();
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if(newProps.brand) {
      this.setState({
        brand     : newProps.brand,
        page      : newProps.page,
        loggedIn  : localStorage.getItem('authToken') ? true : false,
        token     : localStorage.getItem('authToken'),
        showLogin : newProps.showLogin,
      });

      if(localStorage.getItem('authUser')) {
        let user = JSON.parse(localStorage.getItem('authUser'));
        this.setState({
          userType: user.type,
          userImage: user.image,
    	  });
      }
    }
  }

  // Close dropdown on scroll
  handleScroll = (event) => {
    if(this.state.showDropdown) {
      this.setState({
        showDropdown: false
      });
    }
  }

  // Close dropdown oro login form on click outside
  handleClickOutside(event) {
    if (this.state.showDropdown && this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({
        showDropdown: false
      });
    }

    if (this.state.showLoginModal && this.loginFormRef && !this.loginFormRef.current.contains(event.target) && !this.wrapperRef.current.contains(event.target)) {
      this.setState({
        showLoginModal: false
      });
    }
  }

  handleChange = name => event => {
		this.setState({
			[name]: event.target.value
		});

		this.validateField(name, event.target.value);
	};

  validateField(fieldName, value) {
		let emailValid = this.state.emailValid;
		let passwordValid = this.state.passwordValid;

		switch (fieldName) {
			case 'email':
				emailValid = value.match(/^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) ? true : false;
				break;
			case 'password':
				passwordValid = (value.length >= 2) ? true : false;
				break;
			default:
				break;
		}

		this.setState({
			emailValid: emailValid,
			passwordValid: passwordValid
		});
	}

  handleSubmit = evt => {
		evt.preventDefault();

    this.setState({
      showError: false,
      errorText: '&nbsp;'
    });

		let self = this;

    if(!this.state.emailValid) {
      this.setState({
        showError: true,
        errorText: 'Enter valid email and password!'
      });

      return false;
    }

		axios.post(window.API + 'authorize/email', {
			email: this.state.email,
      brand: this.state.brand.link,
      page: this.state.page,
      redirect: window.location.href.split('?')[0]
		},{
      headers: {
        Pragma: 'no-cache',
      }
    }).then(function (response) {

      // Status OK, save token in localstorage, reload page
      if(response.data.status) {
        if (response.data.url) {
          window.location.href = response.data.url;
        } else {
          self.setState({
            screen: 'password'
          });
        }
      } else {
        self.setState({
          showError: true,
          errorText: response.data.user ? 'You don\'t have access to this brand' : 'Incorrect email!'
        });
      }
		}).catch(error => {
      self.setState({
        showError: true,
        errorText: error
      });
		})
	};

  handleSubmitPassword = evt => {
		evt.preventDefault();

    this.setState({
      showError: false,
      errorText: ''
    });

		let self = this;

    if(!this.state.passwordValid) {
      this.setState({
        showError: true,
        errorText: 'Enter password!'
      });

      return false;
    }

		axios.post(window.API + 'authorize/password', {
			email: this.state.email,
			password: this.state.password,
      brand: this.state.brand.link,
      page: this.state.page
		},{
      headers: {
        Pragma: 'no-cache',
      }
    }).then(function (response) {

      // Status OK, save token in localstorage, reload page
      if(response.data.status) {
        localStorage.setItem('authToken', response.data.token);
        localStorage.setItem('authUser', JSON.stringify(response.data.user));
        window.location.reload();
      } else {
        self.setState({
          showError: true,
          errorText: 'Incorrect password!'
        });
      }
		}).catch(error => {
      self.setState({
        showError: true,
        errorText: error
      });
		})
	};

  onEmailFocus = () => {
    this.setState({ emailFocus: true })
  }

  onEmailBlur = () => {
    this.setState({ emailFocus: false })
  }

  onPasswordFocus = () => {
    this.setState({ passwordFocus: true })
  }

  onPasswordBlur = () => {
    this.setState({ passwordFocus: false })
  }

  render () {
    return (
      <div>
        {this.state.showLogin &&
          <div>
            <div onMouseLeave={this.closeDropdown} ref={this.wrapperRef}>
              <div className="auth">
                {this.state.loggedIn &&
                <div className="auth__avatar" onMouseEnter={this.toggleDropdown} onClick={this.toggleDropdown}>
                  <img src={this.state.userImage} alt="" />
                </div>
                }

                {!this.state.loggedIn &&
                <div className="auth__button" onClick={this.toggleLogin}>Login</div>
                }
              </div>
              <div className="dropdown-box">
              {this.state.showDropdown &&
              <ul className="dropdown">
                {this.state.userType && editors.includes(this.state.userType) &&
                <li><a href={process.env.REACT_APP_API_URL + '/auth?access_token=' + this.state.token + '&url=brand/' + this.state.brand.hash + '/page/' + this.state.page.page_id + '/' + this.state.page.url}>Edit mode</a></li>
                }

                {!this.state.brand.organization || this.state.brand.organization && this.state.brand.organization.dashboard &&
                <li><a href={process.env.REACT_APP_API_URL + '/auth?access_token=' + this.state.token + '&url=dashboard'}>Dashboard</a></li>
                }

                {!this.state.brand.organization || this.state.brand.organization && this.state.brand.organization.dashboard &&
                <li><a href={process.env.REACT_APP_API_URL + '/auth?access_token=' + this.state.token + '&url=dashboard/profile'}>My profile</a></li>
                }

                <li onClick={this.logOut}>Logout</li>
              </ul>
              }
              </div>
            </div>

            <div ref={this.loginFormRef} className={'login-modal' + (this.state.showLoginModal ? ' visible' : '')}>
              <div className="login-modal__form">
                <form onSubmit={this.handleSubmit} noValidate>
                  <div className="form-error">
                    <div className={this.state.showError ? 'visible' : ''}>{this.state.errorText}</div>
                  </div>

                  {this.state.screen === 'email' &&
                  <div>
                    <div className="form-group">
                      <label className={this.state.email || this.state.emailFocus ? 'active' : ''}>Enter email</label>
                      <input
                        type="email"
                        value={this.state.email}
                        onFocus={this.onEmailFocus}
                        onBlur={this.onEmailBlur}
                        onChange={this.handleChange('email')}
                        />
                    </div>

                    <div className="form-group">
                      <input type="submit" value="Continue" onClick={this.handleSubmit} />
                    </div>
                  </div>
                  }

                  {this.state.screen === 'password' &&
                  <div>
                    <div className="form-group">
                      <label className={this.state.password || this.state.passwordFocus ? 'active' : ''}>Enter password</label>
                      <input
                        type="password"
                        value={this.state.password}
                        onFocus={this.onPasswordFocus}
                        onBlur={this.onPasswordBlur}
                        onChange={this.handleChange('password')}
                        />
                    </div>

                    <div className="form-group">
                      <input type="submit" value="Login" onClick={this.handleSubmitPassword} />
                    </div>
                  </div>
                  }
                </form>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default Auth;
