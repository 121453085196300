import React, { Component } from 'react';

class MultibrandSwitcherLogo extends Component {

  constructor() {
    super();
    this.state = {
      brand: [],
      project: [],
      image: ''
    }
  }

  componentDidMount() {
    this.setState({
      brand: this.props.brand,
      project: this.props.project,
      image: this.props.project.icon_default ? this.props.project.icon_default : this.props.project.image
    });
  }

  _imageOnMouseOver  = () => {
    if (this.state.project.icon_hover) {
      this.setState({
        image: this.state.project.icon_hover
      });
    }
  }

  _imageOnMouseOut  = () => {
    if (this.state.project.icon_hover) {
      this.setState({
        image: this.state.project.icon_default ? this.state.project.icon_default : this.state.project.image
      });
    }
  }

  render () {
    return (
      <div
        className="multibrand-switcher__logo"
        style={{
          borderRadius: this.state.brand.multibrand_radius+'px',
          outlineWi: this.state.brand.multibrand_border+'px',
          backgroundImage: 'url(' + this.state.image + ')',
          backgroundColor: this.state.project.menu_bg_color,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '40px'
        }}
        onMouseOver={() => { this._imageOnMouseOver() }}
        onMouseOut={() => { this._imageOnMouseOut() }}>
      </div>
    );
  }
}

export default MultibrandSwitcherLogo;
