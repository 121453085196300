import React, { Component } from 'react';
import axios from "axios";
import TextareaAutosize from 'react-textarea-autosize';
import './Request.css';

import circleVideo from 'assets/video/Logo_loop_white.mp4';

class Request extends Component {
  constructor() {
    super();
    this.state = {
      email      : '',
      text       : '',
      emailValid : false,
      brand      : '',
      studio     : '',
      page       : '',
      showError  : false,
      errorText  : 'Enter valid email!',
      charLimit  : 300,
      isSent     : false,
      isSending  : false,
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.setState({
      brand: this.props.brand,
      studio: this.props.studio,
      page: this.props.page,
      menu: this.props.menu,
    });

    let user = JSON.parse(localStorage.getItem('authUser'));
      
    if (user) {
      this.setState({
        email: user.email,
        emailValid: true
      });
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if(newProps.brand) {
      this.setState({
        brand: newProps.brand,
        studio: newProps.studio,
        page: newProps.page,
        menu: newProps.menu,
        isSent: false,
        showError: false,
  	  });

      let user = JSON.parse(localStorage.getItem('authUser'));
      
      if (user) {
        this.setState({
          email: user.email,
          emailValid: true
        });
      }
    }
  }

  handleChange = name => event => {
    if (name === 'text' && event.target.value.length > this.state.charLimit) return false;

		this.setState({
			[name]: event.target.value
		});

		this.validateField(name, event.target.value);
	};

  validateField(fieldName, value) {
		let emailValid = this.state.emailValid;

		switch (fieldName) {
			case 'email':
				emailValid = value.match(/^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) ? true : false;
				break;
			default:
				break;
		}

		this.setState({
			emailValid: emailValid,
		});
	}

  handleSubmit = evt => {
		evt.preventDefault();

    if(this.state.isSending) {
      return false;
    }

    this.setState({
      showError: false,
      errorText: '',
      isSending: true
    });

		let self = this;

    if(!this.state.emailValid) {
      this.setState({
        showError: true,
        errorText: 'Enter valid email!',
        isSending: false
      });

      return false;
    }

		axios.post(window.API + 'request', {
			email: this.state.email,
			text: this.state.text,
      brand: this.state.brand.link,
      studio: this.state.studio,
      page: this.state.page.page_id
		},{
      headers: {
        Pragma: 'no-cache',
      }
    }).then(function (response) {

      // Status OK
      if (response.data.status === 'ok') {
        self.setState({
          isSent: true,
          isSending: false
    	  });
      } else if (response.data.status === 'exist') {
        self.setState({
          showError: true,
          errorText: 'Sign in. You already have access!',
          isSending: false
        });
      } else if (response.data.status === 'notexist') {
        self.setState({
          showError: true,
          errorText: 'Project not found!',
          isSending: false
        });
      } else {
        self.setState({
          showError: true,
          errorText: 'Enter valid email!',
          isSending: false
        });
      }
		}).catch(error => {
      self.setState({
        showError: true,
        errorText: error,
        isSending: false
      });
		})
	};

  render () {
    return (
      <div>
        <div className={this.state.menu && this.state.menu.length > 0 ? 'login login--with-menu' : 'login'}>
          {this.state.isSent &&
          <div className="login__form">
            <video autoPlay loop muted playsInline>
              <source src={circleVideo} type="video/mp4" />
            </video>
            <h1 className="no--lock">Access requested</h1>
            <p>Your access will be granted when<br/>Brand Owner will review your request</p>

            <div>
              <div className="form-group">
                <input type="submit" value="Back" onClick={() => this.props.switchScreen('login',this.state.brand,this.state.studio,this.state.page)} className="cancel" />
              </div>
            </div>
          </div>
          }

          {!this.state.isSent &&
          <div className="login__form">
            <h1>Request access</h1>

            <div>
              <form onSubmit={this.handleSubmit} noValidate>
                <div className="form-error">
                  <div className={this.state.showError ? 'visible' : ''}>{this.state.errorText}</div>
                </div>

                <div className="form-group">
                  <input
                    type="email"
                    value={this.state.email}
                    placeholder="Enter email"
                    onChange={this.handleChange('email')}
                    />
                </div>

                <div className="form-group form-group--counter">
                  <TextareaAutosize
                    value={this.state.text}
                    placeholder="Reason to access"
                    onChange={this.handleChange('text')}
                    minRows={2}
                    maxRows={10}
                    data-gramm="false"
                   />
                  <span>{this.state.charLimit - this.state.text.length}</span>
                </div>

                <div className="form-group two-buttons">
                  <input type="submit" value={this.state.isSending ? 'Wait...' : 'Submit'} onClick={this.handleSubmit} />
                  <input type="submit" value="Cancel" onClick={() => this.props.switchScreen('login', this.state.brand, this.state.studio, this.state.page)} className="cancel" />
                </div>
              </form>
            </div>
          </div>
          }
        </div>
      </div>
    );
  }
}

export default Request;
