import React, { Component } from 'react';
import {Controlled as CodeMirror} from 'react-codemirror2'

import 'codemirror/mode/xml/xml.js';
import 'codemirror/mode/css/css.js';
import 'codemirror/mode/javascript/javascript.js';

import './Snippet.css';

class Snippet extends Component {

  constructor() {
    super();
    this.state = {
      data: [],
      full_width: false,
    }
    this.instance = null;
  }

  componentDidMount() {
    this.setState({
      data: this.props.data,
      full_width: this.props.fullWidth,
    });
    setTimeout(() => {
      this.instance.refresh();
    }, 500);

    //TODO - there is one problem left - vertical scroll issue to fix
        
  }

  render () {
    return (
      <div className="module_snippet">
        <div className="dynamic-content" style={{'width':(this.state.full_width ? '100%' : this.state.data.width+'px'), 'left':(this.state.full_width ? 0 : this.state.data.left)+'px', 'marginTop':(this.state.full_width ? 0 : this.state.data.top)+'px'}}>
          <div className="module_snippet__input">
            <CodeMirror
              value={this.state.data.code}
              options={{
                theme: 'rubyblue',
                mode: this.state.data.type,
                readOnly     : true,
                lineNumbers  : true,
                indentUnit   : 4,
                scrollbarStyle : null
              }}
              editorDidMount={editor => { this.instance = editor }}
              
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Snippet;
