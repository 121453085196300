import React, { Component } from 'react';

import imgDo       from 'assets/img/ico-check.svg';
import imgDont     from 'assets/img/ico-delete.svg';

import ShareLocation from '../../partials/share/ShareLocation';
import VideoBackground from '../../partials/video/VideoBackground';

import { LazyLoadImage }
from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import 'react-photoswipe/lib/photoswipe.css';
import {PhotoSwipe} from 'react-photoswipe';

class Dodont extends Component {

  constructor() {
    super();
    this.state = {
      data: [],
      items: [],
      gallery: [],
      galleryOpen: false,
      zoomImages: false,
      x: 0,
      y: 0,
    }
  }

  componentDidMount() {

    // Set gallery images
    let gallery = [];
    for(let i = 0; i < this.props.data.items.length; i++) {
      let image = {
        src: this.props.data.items[i].image_1600,
        w: this.props.data.items[i].width ? this.props.data.items[i].width : 1500,
        h: this.props.data.items[i].height ? this.props.data.items[i].height : 1500,
      }

      this.props.data.items[i].attachmentClass = 'btn-download-float';
      this.props.data.items[i].x = 0;
      this.props.data.items[i].y = 0;

      gallery.push(image);
    }

    this.setState({
      data: this.props.data,
      items: this.props.data.items,
      zoomImages: this.props.data.gallery_mode === 1,
      gallery: gallery
    });

    const urlParams = new URLSearchParams(window.location.search);

    if(urlParams.get('p') && urlParams.get('m')*1 === this.props.data.id) {
      this.props.data.items.map((item, index) => {
        if(item.id === urlParams.get('p')*1) {
          let self = this;

          setTimeout(function(){
            self.showModal(item,index);
          },1000);
        }
      });
    }
  }

  imageHasDownloads = (item) => {
    return item.attachments && item.attachments.length > 0;
  }

  showModal = (item,index) => {
    if(!this.imageHasDownloads(item)) return false;

    let newItem = item;
    newItem.image = item.filename;
    this.setState({ attachmentClass: 'btn-download-float' });
    this.props.openModal(item,this.state.gallery,index);
  }

  openPhotoSwipe = (index) => {
    if(!this.state.zoomImages) return false;
    this.setState({
      galleryOpen: true,
    });
  };

  handleClose = () => {
    this.setState({
      galleryOpen: false,
    });
  };

  _onMouseEnter(e, item) {
    let items = this.state.items;
    let index = items.findIndex(image => image.id === item.id);
    items[index].attachmentClass = 'btn-download-float visible';
    this.setState({ items: items });
  }

  _onMouseMove(e, item) {
    let items = this.state.items;
    let index = items.findIndex(image => image.id === item.id);
    items[index].x = e.clientX + 5;
    items[index].y = e.clientY - 30;
    this.setState({ items: items });
  }

  _onMouseLeave(e, item) {
    let items = this.state.items;
    let index = items.findIndex(image => image.id === item.id);
    items[index].attachmentClass = 'btn-download-float';
    this.setState({ items: items });
  }

  render () {
    const { galleryOpen, gallery } = this.state;

    return (
      <div className="doDontModule">
        <div
          className={'module' + (this.state.data.show_image_desc === 1 ? ' module--grid-titles' : '')}
          style={{
            paddingTop:this.state.data.row_padding_top+'px',
            paddingBottom:this.state.data.row_padding_bottom+'px',
            backgroundColor:this.state.data.background,
            backgroundImage:this.state.data.bg_image ? 'url('+this.state.data.bg_image+')' : 'none',
            backgroundPosition:this.state.data.bg_image_x+'% '+this.state.data.bg_image_y+'%'
          }}
        >
          <VideoBackground src={this.state.data.bg_video} />
          {this.state.data.dim === 1 && (
            <div className="module-bg-dim"></div>
          )}
          <ShareLocation module={this.state.data.id} />
          <div className={'module-grid module-grid--'+this.state.data.layout+' row column-padding-'+this.state.data.column_padding}>
            <div className="col-12">
              {this.state.data.text && (
              <div className="module_grid__text">
                <div className="dynamic-content" style={{'width':this.state.data.text_width+'px', 'left':this.state.data.text_left+'px', 'top':this.state.data.text_top+'px'}}>
                  <div className="editor-content" dangerouslySetInnerHTML={{__html: this.state.data.text}}></div>
                </div>
              </div>
              )}
            </div>

            <div className="images row autoclear" style={{margin: '0 0 20px'}}>
                {this.state.items.map((item, index) =>
                <div key={item.id} className={'col-12 equal col-sm-'+this.state.data.layout + (this.state.data.show_image_desc !== 1 ? ' equal--no-titles' : '')}>
                  <div className="image__image">
                    <div
                      style={{cursor: this.imageHasDownloads(item) ? 'pointer' : (this.state.zoomImages ? 'zoom-in' : 'default')}}
                      onMouseEnter={(e) => { this._onMouseEnter(e,item) }}
                      onMouseMove={(e) => { this._onMouseMove(e,item) }}
                      onMouseLeave={(e) => { this._onMouseLeave(e,item) }}
                      onClick={(e) => { e.preventDefault(); this.imageHasDownloads(item) ? this.showModal(item,index) : this.openPhotoSwipe(index) }}
                    >
                      <img
                        alt=""
                        src={item.filename}
                        className={item.max_height === '240px' ? 'maxHeight' : ''}
                      />
                    </div>

                    {this.imageHasDownloads(item) && (
                    <div className={item.attachmentClass} style={{left: item.x, top:item.y }} onClick={(e) => { e.preventDefault(); this.showModal(item,index) }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><defs><path id="wee3a" d="M1468 2620v4a2 2 0 0 1-2 2h-14a2 2 0 0 1-2-2v-4"/><path id="wee3b" d="M1454 2615l5 5 5-5"/><path id="wee3c" d="M1459 2620v-12"/></defs><g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3a"/></g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3b"/></g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3c"/></g></g></svg>
                    </div>
                    )}
                  </div>

                  {this.state.data.show_image_desc === 1 && (
                  <div className={'image__title' + (this.state.data.show_icons !== 1 ? ' image__title--no-icons' : '')}>
                    <img src={item.icon === 'do' ? imgDo : imgDont} alt="" />
                    <span style={{color:this.state.data.title_color}}>{item.title}</span>
                  </div>
                  )}
                </div>
                )}
              </div>
          </div>
        </div>

        <PhotoSwipe
          isOpen={galleryOpen}
          items={gallery}
          options={{
            captionEl: false,
            shareEl: false,
            closeOnScroll: true,
            zoomEl: false,
            history: false
          }}
          onClose={this.handleClose}
        />
      </div>
    );
  }
}

export default Dodont;
