import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from '@rollbar/react';
import axios from 'axios';

import Loader from './components/partials/loader/Loader';
import Home from './components/base/home/Home';
import Page from './components/base/page/Page';
import Error404 from './components/base/error/Error';
import Expired from './components/base/expired/Expired';

require('dotenv').config();

const appVersion = '2.6.4';

const api = axios.create({
  headers: { Pragma: 'no-cache' },
});

const rollbarConfig = {
  accessToken: '5047cc4a81a8452db37994f95e99284e',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: 'production',
    client: {
      javascript: {
        code_version: '1.0.0',
        source_map_enabled: true
      }
    }
  }
};

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      customDomain: false,
      studioDomain: false,
      brand: false,
      loadRouting: false
    }
  }

  componentWillMount() {
    api.get(window.API+'domain?domain='+window.location.hostname)
      .then(res => {
        this.setState({
          customDomain: res.data.domain,
          studioDomain: res.data.studio,
          brand: res.data.brand,
          loadRouting: true
        })
      })
  }

  render() {
    return (
      <Provider config={rollbarConfig}>
        <Router>
          <div data-version={appVersion}>
            {this.state.loadRouting &&
            <div className="app-content">
              {this.state.customDomain &&
              <Switch>
                <Route exact path="/404" component={Error404}/>
                <Route exact path="/expired" component={Expired}/>
                <Route exact path="/" render={(props) => <Page brand={this.state.brand} domain={this.state.customDomain} studio={this.state.studioDomain} {...props}/>}/>
                <Route exact path="/:menuslug" render={(props) => <Page brand={this.state.brand} domain={this.state.customDomain} studio={this.state.studioDomain} {...props}/>}/>
                <Route exact path="/:menuslug/:pageslug" render={(props) => <Page brand={this.state.brand} domain={this.state.customDomain} studio={this.state.studioDomain} {...props}/>}/>
                <Route exact path="/:menuslug/:pageslug/:subslug" render={(props) => <Page brand={this.state.brand} domain={this.state.customDomain} studio={this.state.studioDomain} {...props}/>}/>
                <Route component={Home}/>
              </Switch>
              }

              {!this.state.customDomain &&
              <Switch>
                <Route exact path="/" render={(props) => <Home studio={this.state.studioDomain} {...props}/>}/>
                <Route exact path="/404" component={Error404}/>
                <Route exact path="/expired" component={Expired}/>
                <Route exact path="/:brand" render={(props) => <Page brand={this.state.brand} studio={this.state.studioDomain} {...props}/>}/>
                <Route exact path="/:brand/:menuslug" render={(props) => <Page brand={this.state.brand} studio={this.state.studioDomain} {...props}/>}/>
                <Route exact path="/:brand/:menuslug/:pageslug" render={(props) => <Page brand={this.state.brand} studio={this.state.studioDomain} {...props}/>}/>
                <Route exact path="/:brand/:menuslug/:pageslug/:subslug" render={(props) => <Page brand={this.state.brand} studio={this.state.studioDomain} {...props}/>}/>
                <Route component={Home}/>
              </Switch>
              }
            </div>
            }

            <Loader />
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
