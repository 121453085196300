import React, { Component } from 'react';

import './AudioPlayer.css';

import playerPlay from 'assets/img/ico-player-play.svg';
import playerPause from 'assets/img/ico-player-pause.svg';
import playerVolume from 'assets/img/ico-player-volume.svg';
import playerAudio from 'assets/img/ico-player-audio.svg';
import playerDownload from 'assets/img/btn-download.svg';

class AudioPlayer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      audio: {},
      audioStatus: 'ended',
      audioFile: {},
      volume: 0,
      intervalId: false,
      idleIntervalId: false,
      currentLabel: '0:00',
      durationLabel: '0:00',
      progressbar: 0,
      ctime: 0,
      audioIdle: 0,
    }
  }

  componentDidMount() {
    var self = this;

    // Jump on track playback
    document.getElementById('seek-obj-container').addEventListener('click', function (e) {
        let x = e.pageX - (document.getElementById('player__progress').offsetLeft - this.offsetWidth/2);

        let audio = self.state.audioFile;
        let jumpToPercents = Math.floor(x/this.offsetWidth*100);
        let jumpToSeconds  = Math.floor(audio.duration/100*jumpToPercents);

        audio.currentTime = jumpToSeconds;
    });

    this.setState({volume: localStorage.getItem('volume') ? localStorage.getItem('volume') : 0.6});
    document.getElementById('volume-track').addEventListener('click', function (e) {
        let x = e.pageX - this.offsetLeft;

        self.setState({volume: x/100});

        let audio = self.state.audioFile;
        audio.volume = x/100;
        localStorage.setItem('volume', x/100);
        self.setState({audioFile: audio, volume: x/100});
    });

    //var intervalId = setInterval(this.timer, 1000);
    //var idleIntervalId = setInterval(this.idleTimer, 1000);

    //this.setState({
     //intervalId: intervalId,
     // idleIntervalId: idleIntervalId
    //});
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
    //clearInterval(this.state.idleIntervalId);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if(newProps.audioStatus !== this.state.audioStatus){
      this.setState({audioStatus: newProps.audioStatus});

      if(newProps.audioStatus === 'play'){
        var intervalId = setInterval(this.timer, 100);

        this.setState({
         intervalId: intervalId,
        });

        this._playAudio(newProps.audio);
      }

      if(newProps.audioStatus === 'resume'){
        this._resumeAudio(newProps.audio);
      }

      if(newProps.audioStatus === 'paused'){
        this._pauseAudio(newProps.audio);
      }
    }
  }

  timer = () => {
    if(!this.state.audio.id) return;

    let ctime = this.state.audioFile.currentTime.toFixed(0);
    let currentLabel = this.ntot(this.state.audioFile.currentTime);
    let progressbar = (this.state.audioFile.currentTime / this.state.audioFile.duration);
    let playProgress = Math.abs(progressbar*100).toFixed(0);
    let durationLabel = this.ntot(this.state.audioFile.duration);

    this.setState({
      ctime: ctime,
      currentLabel: currentLabel,
      progressbar: progressbar ? progressbar : 0,
      durationLabel: durationLabel,
    })

    this.props.changeProgress(playProgress);

    if(progressbar === 1){
      clearInterval(this.state.intervalId);
      this.props.changeAudioStatus('ended');
    }
  }

  idleTimer = () => {
    /*
    if(this.state.audioIdle != 0){
      this.state.audioIdle = this.state.audioIdle + 1;
    }

    if(this.state.audioIdle == 30){
      this.props.hideAudioPlayer();
      this.props.changeAudioStatus('ended');
    }
    */
  }

  ntot = (secs) => {
    let hr  = Math.floor(secs / 3600);
    let min = Math.floor((secs - (hr * 3600))/60);
    let sec = Math.floor(secs - (hr * 3600) -  (min * 60));
    if (min < 10){
        min = "0" + min;
    }
    if (sec < 10){
        sec  = "0" + sec;
    }

    if(isNaN(min)){
      return '0:00';
    }

    return min + ':' + sec;
  }

  _playAudio = (item) => {
    this.props.changeProgress(0);

    let audio = false;

    if(!this.state.audio.id){
      audio = new Audio();
    }else{
      audio = this.state.audioFile;
      audio.pause();
    }

    audio.src = item.audio;

    audio.play();

    this.setState({audio: item, audioStatus: 'play', audioFile: audio, audioIdle: 0, progressbar: 0});
  }

  _resumeAudio = (item) => {
    let audio = this.state.audioFile;
    audio.play();
    this.setState({audioStatus: 'resume', audioFile: audio, audioIdle: 0});
  }

  _pauseAudio = (item) => {
    let audio = this.state.audioFile;
    audio.pause();
    this.setState({audioStatus: 'paused', audioFile: audio, audioIdle: 1});
  }

  _muteAudio = () => {
    let audio = this.state.audioFile;
    audio.volume = 0;
    this.setState({audioFile: audio, volume: 0});
    localStorage.setItem('volume', 0);
  }

  render () {
    return (
      <div>
        <div className={'audio-player' + (this.props.showAudioPlayer ? ' visible' : '')}>
          <div className="audio-player__wrapper">
            <div className="audio-player__icon">
              <img src={playerAudio} alt="" />
            </div>

            <div className="audio-player__data">
              <h6>{this.state.audio.title}</h6>

              {this.state.audio.attachments && this.state.audio.attachments.length > 0 &&
              <button onClick={() => this.props.openModal(this.state.audio)}>
                Download
                <span><img src={playerDownload} alt="" /></span>
              </button>
              }
            </div>

            <div className="audio-player__progress" id="player__progress">
              <span id="seek-from">{this.state.currentLabel}</span>
              <span id="seek-obj-container">
                <progress value={this.state.progressbar} max="1"></progress>
                <span className="seek-dot" style={{left: (this.state.progressbar*100-1) + '%'}}></span>
              </span>
              <span id="seek-to">{this.state.durationLabel ? this.state.durationLabel : '0:00'}</span>
            </div>

            <div className="audio-player__volume">
              <img src={playerVolume} alt="" onClick={() => this._muteAudio()} />
              <span id="volume-track">
                <progress value={this.state.volume} max="1" id="volumeLevel"></progress>
                <span className="seek-dot" style={{left: (this.state.volume*100-1) + '%'}}></span>
              </span>
            </div>

            {this.props.audioStatus !== 'play' && this.props.audioStatus !== 'resume' &&
            <div className="audio-player__buttons button--play" onClick={() => this.props.changeAudioStatus('play')}>
              <img src={playerPlay} alt="" />
            </div>
            }

            {this.props.audioStatus === 'play' &&
            <div className="audio-player__buttons" onClick={() => this.props.changeAudioStatus('paused')}>
              <img src={playerPause} alt="" />
            </div>
            }

            {this.props.audioStatus === 'resume' &&
            <div className="audio-player__buttons" onClick={() => this.props.changeAudioStatus('paused')}>
              <img src={playerPause} alt="" />
            </div>
            }

            <div className="audio-player__close" onClick={() => this.props.closeAudio()}>Close</div>
          </div>
        </div>
      </div>
    );
  }
}

export default AudioPlayer;
