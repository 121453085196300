import React, { Component } from 'react';
import axios from "axios";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './Dash.css';

import dashLogo from 'assets/img/dash-logo.png';

let dashLoaderTimeout;

class Dash extends Component {

  constructor() {
    super();
    this.state = {
      brand: false,
      portal: false,
      loading: false,
      foldersOpen: false,
      activeFolder: false,
      hoverFolder: false,
      accountId: false,
      attributeId: false,
      dashToken: false,
      metadataTitle: false,
      folders: [],
      assets: [],
      downloadProcess: false,
      open: false,
      showDashLoader: false,
      dashLoaderTimeout: false,
      showImageDownload: false,
      showImageInfo: false,
      showDashImageLoader: false,
      openAsset: false,
      imageAsset: false,
      activePortal: false,
      imageLoaded: false,
      activeFolderTitle: false,
      showFoldersTree: false,
      portalNotFound: false,
      portalTitle: false,
      portalWelcome: false,
    }

    this.toggleFolders = this.toggleFolders.bind(this);
    this.clearFolders = this.clearFolders.bind(this);
    this.bytesToSize = this.bytesToSize.bind(this);
    this.setInfoRef = this.setInfoRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.dashPortalOpen) {
      if (newProps.portal.portal_id !== this.state.activePortal) {
        this.setState({
          brand: newProps.brand,
          portal: newProps.portal,
          open: true,
          foldersOpen: false,
          folders: [],
          assets: [],
          activePortal: newProps.portal.portal_id,
          activeFolderTitle: false,
          portalTitle: false,
          portalWelcome: false
        });
        this.getPortal(newProps.brand.link, newProps.portal);
        this.getFolders(newProps.brand.link, newProps.portal);
        document.body.classList.add('no--overflow');
      } else {
        this.setState({
          open: true,
        });
        document.body.classList.add('no--overflow');
      }
    }
  }

  // Folder toggler
  toggleFolders = () => {
    this.setState({
      foldersOpen: this.state.foldersOpen ? false : true
    });
  }

  // Get portal data
  getPortal = (id, portal) => {
    let self = this;
    this.toggleDashLoader(true);
    axios.post(window.EDITOR_URL + '/public/' + id + '/dash/get-portal', { portal: portal.portal_id },{
      headers: { Pragma: 'no-cache' }
    }).then(function (response) {
      if (response.data) {
        self.setState({
          portalTitle: response.data.name,
          portalWelcome: response.data.welcomeMessage.title,
        });
      } else {
        self.setState({
          portalWelcome: true
        });
      }
    }).catch(error => {});
  }

  // Main folder data
  getFolders = (id, portal) => {
    let self = this;
    this.toggleDashLoader(true);
    axios.post(window.EDITOR_URL + '/public/' + id + '/dash/folder-settings', { portal: portal.portal_id },{
      headers: { Pragma: 'no-cache' }
    }).then(function (response) {
      if (response.data.access_token) {
        self.setState({
          accountId: response.data.result.accountId,
          attributeId: response.data.result.attributeId,
          dashToken: response.data.access_token,
          portalNotFound: false,
          foldersOpen: false
        });
        self.getAttributes();
      } else {
        self.setState({
          portalNotFound: true
        });
        self.dashLoderHide();
      }
    }).catch(error => {});
  }

  // Clear folders
  clearFolders = () => {
    this.setState({
      foldersOpen: false,
      activeFolder: false,
      hoverFolder: false,
      showFoldersTree: false,
      folders: [],
      assets: [],
      activeFolderTitle: false
    });
    this.getFolders(this.state.brand.link, this.state.portal);
  }

  // Attributes list
  getAttributes = () => {
    let self = this;
    axios.get('https://api-v1.brightdash.app/attributes',{
      headers: { 'Authorization': 'Bearer ' + self.state.dashToken }
    }).then(function (response) {
      let metadataTitle = response.data.find((obj) => obj.result.name === 'Title');
      if (metadataTitle) {
        metadataTitle = metadataTitle.result.id;
      }

      self.setState({
        metadataTitle: metadataTitle
      });

      self.mainFolders();
      self.toggleDashLoader(false);
    }).catch(error => {});
  }

  // Main folders list
  mainFolders = () => {
    let self = this;
    axios.post('https://api-v1.brightdash.app/attribute-option-searches',
      {
        from: 0,
        pageSize: 500,
        criterion: {
          type: 'AND',
          criteria: [
            {
              type: 'FIELD_EQUALS',
              field: 'ATTRIBUTE_ID',
              value: self.state.attributeId
            },
            {
              type: 'FIELD_IS_EMPTY',
              field: 'PARENT_ID'
            }
          ]
        },
        sorts: [
          {
            field: 'POSITION',
            order: 'ASC'
          }
        ]
      },{
        headers: { 'Authorization': 'Bearer ' + self.state.dashToken
      }
    }).then(function (response) {
      if (response.data.results) {
        let folders = [];

        self.setState({showFoldersTree: false});

        if (response.data.results.length == 1 && response.data.results[0].result.numberOfChildren > 0) {
          axios.post(window.EDITOR_URL + '/public/' + self.state.brand.link + '/dash/attribute-option-searches', {
            parent_id: response.data.results[0].result.id,
            token: self.state.dashToken
          },{
            headers: { Pragma: 'no-cache' }
          }).then(function (response) {
            response.data.results.forEach(obj => {
              folders.push(obj.result);
            });

            if (folders.length > 1) {
              self.setState({showFoldersTree: true});
            }
          }).catch(error => {});
        } else {
          response.data.results.forEach(obj => {
            folders.push(obj.result);
          });

          if (folders.length > 1) {
            self.setState({showFoldersTree: true});
          }
        }

        self.latestAssets();
        self.dashLoderHide();
        self.setState({ folders: folders });
      }
    }).catch(error => {});
  }

  // Latest assets
  latestAssets = () => {
    let self = this;
    axios.post('https://api-v1.brightdash.app/asset-searches', {
      from: 0,
      pageSize: 500,
      criterion: {
        type: 'MATCH_ALL'
      },
      sorts: [
        {
          field: {
            type: 'FIXED',
            fieldName: 'DATE_ADDED'
          },
          order: 'DESC'
        }
      ]
    },{
      headers: { 'Authorization': 'Bearer ' + self.state.dashToken }
    }).then(function (response) {
      if (response.data.results) {
        let assets = [];

        response.data.results.forEach(obj => {
          self.formatAsset(obj.result);
          assets.push(obj.result.currentAssetFile);
        });

        self.setState({ assets: assets });
      }
    }).catch(error => {});
  }

  // Expand filder
  expandFolder = (folder) => {
    let items = [...this.state.folders];

    if (folder.expanded) {
      folder.expanded = false;

      this.setState({folder});
    } else {
      let self = this;

      self.dashLoaderShow();

      axios.post(window.EDITOR_URL + '/public/' + self.state.brand.link + '/dash/attribute-option-searches', {
        parent_id: folder.id,
        token: self.state.dashToken
      },{
        headers: { Pragma: 'no-cache' }
      }).then(function (response) {

        folder.expanded = true;

        let folders = [];
        response.data.results.forEach(obj => {
          folders.push(obj.result);
        });

        folder.folders = folders;

        self.setState({folder});
        self.dashLoderHide();
      }).catch(error => {});
    }
  }

  // Browse folder content
  browseFolder = (folder) => {
    this.setState({
      activeFolder: folder.id,
      activeFolderTitle: folder.value
    });

    let self = this;

    self.dashLoaderShow();

    axios.post(window.EDITOR_URL + '/public/' + self.state.brand.link + '/dash/browse-folder', {
      folder_id: folder.id,
      attribute_id: self.state.attributeId,
      token: self.state.dashToken
    },{
        headers: { Pragma: 'no-cache' }
     }).then(function (response) {
      if (response.data.results) {
        let assets = [];

        response.data.results.forEach(obj => {
          self.formatAsset(obj.result);
          assets.push(obj.result.currentAssetFile);
        });

        self.setState({ assets: assets });
        self.toggleFolders();
        self.dashLoderHide();
      }
    }).catch(error => {});
  }

  // Format asset for view
  formatAsset = (asset) => {
    asset.currentAssetFile.title = this.state.metadataTitle ? asset.metadata.values[this.state.metadataTitle][0] : asset.currentAssetFile.filename;
    asset.currentAssetFile.extension = asset.currentAssetFile.filename.substring(asset.currentAssetFile.filename.lastIndexOf('.') + 1);
    asset.currentAssetFile.formattedSize = this.bytesToSize(asset.currentAssetFile.size);
    asset.currentAssetFile.previewUrl = asset.currentAssetFile.previewUrl.toString().replace('?', '/324x324.jpg?');
    return asset;
  }

  // Prepare Dash asset download
  createDashDownload = (asset) => {
    if (this.state.downloadProcess) return false;
    let self = this;

    if (asset.fileType === 'IMAGE') {
      self.openDashImagePortal(asset);
      return false;
    }

    self.setState({ downloadProcess: asset.assetId });

    self.toggleDashLoader(true);

    axios
      .post(window.EDITOR_URL + '/public/' + self.state.brand.link + '/dash/create-asset-download', {
        id: 'item',
        asset_id: asset.assetId,
        token: self.state.dashToken
      }, {
        headers: {
          Pragma: 'no-cache',
          Authorization: self.state.dashToken || ''
        }
      }).then(function (response) {
        let intervalId = setInterval(function(){
          self.getDashDownload(response.data.id);
        }, 1000);

        self.setState({ downloadInterval: intervalId })
      });
  };

  // Prepare Dash Portal image download
  downloadAsset = (width, height) => {
    if (this.state.downloadProcess) return false;
    let self = this;
    let asset = self.state.openAsset;

    self.setState({
      downloadProcess: asset.assetId,
      showDashImageLoader: true
    });

    self.toggleDashLoader(true);

    axios
      .post(window.EDITOR_URL + '/public/' + self.state.brand.link + '/dash/create-portal-asset-download', {
        id: 'item',
        asset_id: asset.assetId,
        width: width,
        height: height,
        format: self.state.format,
        token: self.state.dashToken
      }, {
        headers: {
          Pragma: 'no-cache',
          Authorization: self.state.dashToken || ''
        }
      }).then(function (response) {
        let intervalId = setInterval(function(){
          self.getDashDownload(response.data.id);
        }, 1000);

        self.setState({ downloadInterval: intervalId })
      });
  };

  // Check and return Dash download
  getDashDownload = async (id) => {
    try {
      const response = await axios.post(window.EDITOR_URL + '/public/' + this.state.brand.link + '/dash/get-asset-download/' + id, {
        token: this.state.dashToken
      }, {
        headers: {
          Pragma: 'no-cache',
          Authorization: this.state.dashToken || ''
        }
      });

      if (response.data.type === 'COMPLETED') {
        clearInterval(this.state.downloadInterval);
        this.setState({
          downloadProcess: false,
          showDashImageLoader: false
        });
        this.toggleDashLoader(false);
        window.location.href = response.data.result.successes.item.result.url;
      }
    } catch (err) {
      console.error(err);
    }
  };

  // Open image popup
  openDashImagePortal = (asset) => {
    let imageAsset = {};

    imageAsset.dashUrl = 'https://' + this.state.brand.dash_subdomain + '.dash.app/browse/' + asset.assetId + '?portal=' + this.state.portal.slug;
    imageAsset.image = asset.previewUrl.toString().replace('324x324', '1600x1600');;
    imageAsset.width = asset.dimensions.width;
    imageAsset.height = asset.dimensions.height;
    imageAsset.orientation = imageAsset.width >= imageAsset.height ? 'h' : 'v';
    imageAsset.proportion = imageAsset.orientation == 'h' ? imageAsset.width / imageAsset.height : imageAsset.height / imageAsset.width;

    if (asset.mediaType.subType == 'jpeg') {
      imageAsset.format = 'JPG';
    } else {
      imageAsset.format = asset.mediaType.subType == 'png' || asset.mediaType.subType == 'webp' ? asset.mediaType.subType.toUpperCase() : 'JPG';
    }

    // Large size
    imageAsset.width_l = imageAsset.orientation == 'h' ? 2500 : (2500/imageAsset.proportion).toFixed(0);
    imageAsset.height_l = imageAsset.orientation == 'v' ? 2500 : (2500/imageAsset.proportion).toFixed(0);

    // Medium size
    imageAsset.width_m = imageAsset.orientation == 'h' ? 1200 : (1200/imageAsset.proportion).toFixed(0);
    imageAsset.height_m = imageAsset.orientation == 'v' ? 1200 : (1200/imageAsset.proportion).toFixed(0);

    // Small size
    imageAsset.width_s = imageAsset.orientation == 'h' ? 600 : (600/imageAsset.proportion).toFixed(0);
    imageAsset.height_s = imageAsset.orientation == 'v' ? 600 : (600/imageAsset.proportion).toFixed(0);

    this.setState({
      showImageDownload: true,
      openAsset: asset,
      imageAsset: imageAsset,
      format: imageAsset.format
    });
  }

  setInfoRef(node) {
    this.infoRef = node;
  }

  handleClickOutside(event) {
    if (this.infoRef && !this.infoRef.contains(event.target) && !event.target.classList.contains('download')) {
      this.setState({
        showImageInfo: false
      });
    }
  }

  // Show image download options
  showDownload = () => {
    this.setState({
      showImageInfo: this.state.showImageInfo ? false : true
    });
  }

  // Change image downlaod format
  changeFormat = (format) => {
    this.setState({
      format: format
    });
  }

  // Close image popup
  closeImageDialog = () => {
    this.setState({
      showImageDownload: false,
      imageAsset: false,
      imageLoaded: false
    });
  }

  // Close file browser
  closeDialog = () => {
    this.setState({
      open: false,
    });
    document.body.classList.remove('no--overflow');
  }

  // Close file browser
  toggleDashLoader = (status) => {
    this.setState({ showDashLoader: status });
  }

  // Show or hide Dash loader with delay
  dashLoaderShow = () => {
    let self = this;

    if (dashLoaderTimeout) {
      clearTimeout(dashLoaderTimeout);
      self.setState({ dashLoaderTimeout: false });
    }

    dashLoaderTimeout = setTimeout(function(){
      clearTimeout(dashLoaderTimeout);
      self.setState({ showDashLoader: true, dashLoaderTimeout: false });
    }, 2000);
  }

  dashLoderHide = () => {
    if (dashLoaderTimeout) {
      clearTimeout(dashLoaderTimeout);
      this.setState({ dashLoaderTimeout: false });
    }
    this.setState({ showDashLoader: false });
  }

  // Size converter
  bytesToSize = (bytes) => {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return 'n/a';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    if (i === 0) return bytes + ' ' + sizes[i];
    return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
  }

  // Active hover folder
  _hoverIn = (e,id) => {
    this.setState({
      hoverFolder: id
    });
  }

  // Deactivate hover folder
  _hoverOut = (e) => {
    this.setState({
      hoverFolder: false
    });
  }

  render () {

    const renderFolder = (parent) => {
      return !parent.folders ? false : <ul>
        {parent.folders.map(folder =>
        <li
          key={folder.id}
          className={`${this.state.activeFolder === folder.id ? "active" : ""} ${folder.numberOfChildren > 0 ? "expandable" : "" } ${this.state.hoverFolder === folder.id ? "hover" : ""}`}
          onMouseEnter={(e) => { this._hoverIn(e,folder.id) }}
          onMouseLeave={(e) => { this._hoverOut(e) }}>
          {folder.numberOfChildren > 0 &&
            <span
              className={'arrow' + (folder.expanded ? ' expanded' : '')}
              onClick={() => this.expandFolder(folder)}>
            </span>
          }
          <span
            className="title"
            onClick={() => this.browseFolder(folder)}>
            {folder.value}
          </span>
          {folder.expanded ? renderFolder(folder) : ''}
        </li>
        )}
      </ul>
    }

    return (
      <div>
        <div className={'modal--dash-backdrop' + (this.state.open ? ' modal--open' : '')} onClick={() => { this.closeDialog(); this.props.closeDashPortal() }}></div>
        <div className={'modal--dash' + (this.state.open ? ' modal--open' : '')}>
          <div className="content-wrapper">
            <div className="scroll-inner">
              <div className="modal--dash__head">
                <h3 className={this.state.portalWelcome ? 'with-welcome' : ''}>{this.state.portalTitle}</h3>
                {this.state.portalWelcome &&
                <span>{this.state.portalWelcome}</span>
                }
              </div>

              {this.state.showFoldersTree &&
              <div className="modal--dash_folders">
                <div
                  className={'head' + (this.state.foldersOpen ? ' head--open' : '')}
                  onClick={this.toggleFolders}>
                    <div>{this.state.activeFolderTitle ? '/ ' + this.state.activeFolderTitle : 'Folders'}</div>
                    {this.state.activeFolderTitle && <div onClick={this.clearFolders} className="clear">Clear</div>}
                </div>
                <div className={'list' + (this.state.foldersOpen ? ' list--open' : '')}>
                  <ul className="no-border">
                    {this.state.folders.map(folder =>
                    <li
                      key={folder.id}
                      className={`${this.state.activeFolder === folder.id ? "active" : ""} ${folder.numberOfChildren > 0 ? "expandable" : "" } ${this.state.hoverFolder === folder.id ? "hover" : ""}`}
                      onMouseEnter={(e) => { this._hoverIn(e,folder.id) }}
                      onMouseLeave={(e) => { this._hoverOut(e) }}>
                      {folder.numberOfChildren > 0 &&
                      <span
                        className={'arrow' + (folder.expanded ? ' expanded' : '')}
                        onClick={() => this.expandFolder(folder)}>
                      </span>
                      }
                      <span
                        className="title"
                        onClick={() => this.browseFolder(folder)}>
                        {folder.value}
                      </span>
                      {folder.expanded ? renderFolder(folder) : ''}
                    </li>
                    )}
                  </ul>
                </div>
              </div>
              }

              <div className={'modal--dash__assets' + (!this.state.showFoldersTree ? ' no-folders' : '')}>
                <div className="row">
                  {this.state.assets.map(asset =>
                  <div key={asset.id} className="col-sm-4">
                    <div className="asset" onClick={(e) => {this.createDashDownload(asset)}}>
                      <div className="asset__preview">
                        {asset.fileType === 'IMAGE' &&
                          <img
                            alt=""
                            src={asset.previewUrl}
                          />
                        }

                        {asset.fileType === 'VIDEO' &&
                          <img src={asset.previewUrl} alt="" />
                        }

                        {asset.fileType !== 'IMAGE' && asset.fileType !== 'VIDEO' &&
                        <div className="filetype">
                          <div>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 175" className="file-icon__img">
                              <path _ngcontent-adm-c260="" d="M17.5 0C7.9 0 .1 7.9.1 17.5l-.1 140c0 9.6 7.8 17.5 17.4 17.5h105.1c9.6 0 17.5-7.9 17.5-17.5v-105L87.5 0h-70zm61.3 61.2V13.1l48.1 48.1H78.8z"></path>
                            </svg>
                            <span>{asset.extension}</span>
                          </div>
                        </div>
                        }
                      </div>

                      <div className="asset__data">
                        <div className="title">{asset.title}</div>
                        <div className="details">{asset.extension}, {asset.formattedSize}</div>
                      </div>
                    </div>
                  </div>
                  )}
                </div>

                {!this.state.loading && this.state.activeFolder && this.state.assets.length === 0 &&
                <div className="empty">
                  <div className="empty__icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M5.1 10.8c0-3.2 2.6-5.7 5.8-5.7s5.8 2.6 5.8 5.7-2.6 5.7-5.8 5.7-5.8-2.5-5.8-5.7zm12 4.8c1-1.3 1.6-3 1.6-4.7 0-4.3-3.5-7.8-7.8-7.8S3 6.5 3 10.8s3.5 7.8 7.8 7.8c1.8 0 3.4-.6 4.8-1.6l3.6 3.6c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1.1 0-1.5l-3.5-3.5z"></path>
                    </svg>
                  </div>
                  <div className="empty__title">
                    No results found
                  </div>
                </div>
                }
              </div>

              {this.state.portalNotFound &&
              <div className="empty">
                <div className="empty__icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M5.1 10.8c0-3.2 2.6-5.7 5.8-5.7s5.8 2.6 5.8 5.7-2.6 5.7-5.8 5.7-5.8-2.5-5.8-5.7zm12 4.8c1-1.3 1.6-3 1.6-4.7 0-4.3-3.5-7.8-7.8-7.8S3 6.5 3 10.8s3.5 7.8 7.8 7.8c1.8 0 3.4-.6 4.8-1.6l3.6 3.6c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1.1 0-1.5l-3.5-3.5z"></path>
                  </svg>
                </div>
                <div className="empty__title">
                  Portal not found
                </div>
              </div>
              }
            </div>
          </div>

          <div className="dialog_back">
            <a href={null} onClick={this.closeDialog}>
              <svg xmlns="http://www.w3.org/2000/svg" width="27" height="18" viewBox="0 0 27 18">
                <g>
                  <g><path d="M27.007 7.201H7.172L14.675.003H9.37L-.007 9l9.378 8.997h5.304l-7.503-7.198h19.835z"/></g>
                </g>
              </svg>
            </a>
          </div>

          {this.state.showDashLoader &&
          <div className="modal--dash__loader">
            <img src="https://assets.corebook.io/editor/images/modules/dash-loader.svg" alt="" />
          </div>
          }
        </div>

        <div className={'modal--dash-image' + (this.state.showImageDownload ? ' visible' : '')}>
          <div className="dash-modal-bg"></div>

          <div className="dash-modal-download">
            <div className="dash-modal-download__buttons">
              <button className="close" onClick={this.closeImageDialog}></button>
              <button className="download" onClick={this.showDownload}></button>
            </div>

            <div className="dash-modal-download__image">
              <img style={this.state.imageLoaded ? {} : {display: 'none'}} src={this.state.imageAsset.image} onLoad={() => this.setState({imageLoaded: true})} alt="" />
              {!this.state.imageLoaded &&
              <div className="modal--dash__loader">
                <img src="https://assets.corebook.io/editor/images/modules/dash-loader.svg" alt="" />
              </div>
              }
            </div>

            {this.state.showImageInfo &&
            <div ref={this.setInfoRef} className="dash-modal-download__info">
              <div className="head" onClick={(e) => {this.downloadAsset(this.state.imageAsset.width, this.state.imageAsset.height)}}>
                Original <span>{ this.state.imageAsset.width } x { this.state.imageAsset.height } px, { this.state.format }</span>
              </div>

              <div className="options">
                <div className="options__formats">
                  Download as:
                  <ul>
                    <li className={this.state.format === 'JPG' ? 'active' : ''} onClick={(e) => {this.changeFormat('JPG')}}>JPG</li>
                    <li className={this.state.format === 'PNG' ? 'active' : ''} onClick={(e) => {this.changeFormat('PNG')}}>PNG</li>
                    <li className={this.state.format === 'WEBP' ? 'active' : ''} onClick={(e) => {this.changeFormat('WEBP')}}>WEBP</li>
                  </ul>
                </div>

                <div className="options__sizes">
                  <ul>
                    <li onClick={(e) => {this.downloadAsset(this.state.imageAsset.width_l, this.state.imageAsset.height_l)}}>
                      Large <span>{ this.state.imageAsset.width_l } x { this.state.imageAsset.height_l } px, { this.state.format }</span>
                    </li>
                    <li onClick={(e) => {this.downloadAsset(this.state.imageAsset.width_m, this.state.imageAsset.height_m)}}>
                      Medium <span>{ this.state.imageAsset.width_m } x { this.state.imageAsset.height_m } px, { this.state.format }</span>
                    </li>
                    <li onClick={(e) => {this.downloadAsset(this.state.imageAsset.width_s, this.state.imageAsset.height_s)}}>
                      Small <span>{ this.state.imageAsset.width_s } x { this.state.imageAsset.height_s } px, { this.state.format }</span>
                    </li>
                    <li className="with-url">
                      <a href={this.state.imageAsset.dashUrl} target="_blank" rel="noopener noreferrer">
                        Other sizes <span>Go to Dash portal</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              {this.state.showDashImageLoader &&
              <div className="modal--dash__loader">
                <img src="https://assets.corebook.io/editor/images/modules/dash-loader.svg" alt="" />
              </div>
              }
            </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default Dash;
