import React, { Component }     from 'react';
import { withRouter }  from "react-router-dom";
import { Helmet }               from "react-helmet";

import './Error.css';

import Spinner from 'assets/img/auth-spinner.svg';
import Stone from 'assets/video/gem-resized.mp4';

class Error extends Component {

  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {
    //self.setState({loadClass: 'fade-in'});
    document.body.classList.add('body-loaded');
  }

  render () {
    return (
      <div>
        <Helmet>
          <title>Hidden or does not exist</title>
        </Helmet>

        <div className="error_404">
          <div className="video">
            <video playsInline autoPlay loop muted>
                <source src={Stone} type="video/mp4" />
            </video>
          </div>

          <div className="error_404__content">
              <h1>404</h1>
              <p>This page is hidden or does not exist in <a href="https://corebook.io">Corebook</a> <img src={Spinner} alt="" /></p>
              <p className="grey">If you compromise brand direction,<br/>you loose the core value.</p>
              <p><a href="https://corebook.io" className="button">Show me the way</a></p>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Error);
