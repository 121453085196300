import React, { Component } from 'react';
import Lottie from 'react-lottie';
import * as animationData from './data.json'
import './Loader.css';

class Loader extends Component {

  constructor(props) {
    super(props);
    this.state = {isStopped: false, isPaused: false};
  }

  render () {

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData.default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    return (
      <div className="global-loader">
        <Lottie options={defaultOptions}
            height={108}
            width={384}
            isStopped={this.state.isStopped}
            isPaused={this.state.isPaused}/>
      </div>
    );
  }
}

export default Loader;
