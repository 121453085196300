import React, { Component } from 'react';
import { withRouter }       from "react-router-dom";
import { Helmet }           from "react-helmet";

import './Expired.css';

import Stone from 'assets/video/gem-resized.mp4';

class Expired extends Component {

  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {
    //self.setState({loadClass: 'fade-in'});
    document.body.classList.add('body-loaded');
  }

  render () {
    return (
      <div>
        <Helmet>
          <title>Expired</title>
        </Helmet>

        <div className="error_404">
          <div className="video">
            <video playsInline autoPlay loop muted>
                <source src={Stone} type="video/mp4" />
            </video>
          </div>

          <div className="error_404__content">
              <h1>Expired</h1>
              <p>This link is expired</p>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Expired);
