import React, { Component } from 'react';
import './Home.css';

class Home extends Component {

  componentWillMount() {
    window.location = this.props.studio ? 'https://studio.corebook.io' : 'https://content.corebook.io';
  }

  render () {
    return (
      <div>

      </div>
    );
  }
}

export default Home;
