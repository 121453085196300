import React, { Component } from 'react';

import MultibrandSwitcherLogo from './MultibrandSwitcherLogo';
import './MultibrandSwitcher.css';

class MultibrandSwitcher extends Component {

  constructor() {
    super();
    this.state = {
      brand: [],
    }
  }

  componentDidMount() {
    this.setState({
      data: this.props.brand,
    });
  }

  render () {
    let props = this.props;

    return (
      <div className="multibrand-switcher" style={{
        borderWidth: props.brand.multibrand_divider == 'yes' ? '1px' : '0px',
        borderColor: props.brand.multibrand_divider_color ? props.brand.multibrand_divider_color : props.brand.menu_item_color,
        backgroundColor: props.brand.multibrand_background == 'yes' ? (props.brand.multibrand_background_color ? props.brand.multibrand_background_color : props.brand.menu_bg_color) : 'transparent'
      }}>
        <ul className="multibrand-switcher__brands">
          {props.brand.sidebar_projects.map(project =>
          <li key={project.id} className={project.brand_id === props.brand.id  ? 'active' : ''}>
            <div>
              <a href={project.public}>
                <MultibrandSwitcherLogo brand={props.brand} project={project}></MultibrandSwitcherLogo>
                {props.brand.multibrand_name_hover === 'yes' &&
                <div className="multibrand-switcher__title">{project.title}</div>
                }
              </a>
            </div>
          </li>
          )}
        </ul>
      </div>
    );
  }
}

export default MultibrandSwitcher;
