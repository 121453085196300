import React, { Component } from 'react';

import './Masthead.css';
import VideoBackground from '../../partials/video/VideoBackground';
import ShareLocation from '../../partials/share/ShareLocation';

class Masthead extends Component {

  constructor() {
    super();
    this.state = {
      layout:    'half',
      image:     false,
      bgcolor:   false,
      parallax:  false,
      dim:       false,
      show_text: false,
      text:      '',
      css_width: 0,
      css_top:   0,
      css_left:  0,
      created_at: '',
      updated_at: '',
    }
    this.mastheadRef = React.createRef();
  }

  componentDidMount() {
    this.updateMasthead(this.props.data.masthead);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.updateMasthead(newProps.data.masthead);

    if (newProps.data.masthead.layout === 'fold' && newProps.data.masthead.visible === 1 && newProps.data.masthead.edited === 1 && newProps.data.masthead.show_text && this.mastheadRef.current) {
      let maxPadding = window.innerHeight - this.mastheadRef.current.clientHeight;

      if (maxPadding < newProps.data.masthead.css_top) {
        this.setState({ css_top:maxPadding });
      }
    }
  }

  // On resize event
  updateMasthead = (data) => {
    this.setState({
      id:         data.id,
      layout:     data.layout,
      image:      data.image,
      bg_image_x: data.bg_image_x,
      bg_image_y: data.bg_image_y,
      video:      data.video,
      bgcolor:    data.bgcolor,
      parallax:   data.parallax,
      dim:        data.dim === 1,
      show_text:  data.show_text === 1,
      text:       data.text,
      visible:    data.visible,
      edited:     data.edited,
      css_width:  data.css_width,
      css_top:    data.css_top,
      css_left:   data.css_left,
      created_at: data.created_at,
      updated_at: data.updated_at,
    });
  }

  setClassName = () => {
    let className = 'head-image head-image--' + this.state.layout + (!this.state.image ? ' no-image' : '') + (this.state.parallax ? ' parallax' : '');
    return className;
  }

  render () {
    if(this.state.visible !== 1 || this.state.edited !== 1){
      return (
        <div className={'_scroll_'+this.state.id+' anchor empty-masthead assume-as-first-copy-link'}>&nbsp;</div>
      );
    }else{
      return (
        <div className={'_scroll_'+this.state.id+' anchor assume-as-first-copy-link'}>
          {this.state.visible === 1 && this.state.edited === 1 && (
          <div
            className={this.setClassName()}
            style={
              this.state.image ? {backgroundImage: 'url(' + this.state.image + ')',
              backgroundSize: 'cover',
              backgroundPosition: this.state.bg_image_x+'% '+this.state.bg_image_y+'%',
              backgroundColor: this.state.bgcolor ? this.state.bgcolor : 'none'} : (this.state.bgcolor ? {backgroundColor: this.state.bgcolor} : {})
            }>
            <VideoBackground src={this.state.video} id={this.state.id} />

            {this.state.dim && (
              <div className="head-image_overlay"></div>
            )}

            <ShareLocation first={true} module={this.state.id} />

            {this.state.show_text && (
              <div id="masthead-area" style={{paddingTop:(this.state.css_top < 65 ? 65 : this.state.css_top)+'px'}}>
                <div style={{position:'relative'}}>
                  <div ref={this.mastheadRef} className="dynamic-content" style={{position:'relative','width':this.state.css_width+'px','left':this.state.css_left+'px','top':'0px'}}>
                    <div className="head-image_text editor-content">
                      <div dangerouslySetInnerHTML={{__html: this.state.text}}></div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          )}
        </div>
      );
    }
  }
}

export default Masthead;
