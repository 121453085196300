import React, { Component } from 'react';

import './Content.css';

import Loader from './Loader';
import ShareLocation from '../../partials/share/ShareLocation';
import VideoBackground from '../../partials/video/VideoBackground';

class Content extends Component {

  constructor() {
    super();
    this.state = {
      data: [],
      imageVideoTextPosition: 'bottom'
    }
    this.imageVideoTextMobilePosition = this.imageVideoTextMobilePosition.bind(this);
  }

  componentDidMount() {
    this.setState({
      data: this.props.data,
    });
    this.imageVideoTextMobilePosition();
  }

  // Check first column module text position. Set this position for other grid modules in mobile view 
  imageVideoTextMobilePosition() {
    if (this.props.data.cell_1 && (this.props.data.cell_1.module === 'imagetext' || this.props.data.cell_1.module === 'videotext')) {
      this.setState({
        imageVideoTextPosition: this.props.data.cell_1.text_pos,
      });
    }
  }

  render () {
    return (
      <div>
        <div
          className={'module'}
          style={{
            paddingTop:this.state.data.row_padding_top+'px',
            paddingBottom:this.state.data.row_padding_bottom+'px',
            backgroundColor:this.state.data.background,
            backgroundImage:this.state.data.bg_image ? 'url('+this.state.data.bg_image+')' : 'none',
            backgroundPosition:this.state.data.bg_image_x+'% '+this.state.data.bg_image_y+'%'
          }}
        >
          <VideoBackground src={this.state.data.bg_video} />
          {this.state.data.dim === 1 && (
            <div className="module-bg-dim"></div>
          )}
          <ShareLocation module={this.state.data.id} />
          <div className={'module_content module_content--'+this.state.data.layout+' row column-padding-'+this.state.data.column_padding+' grid-'+this.state.data.grid+' full--'+this.state.data.full_width}>

            <div className="row">
              <div className="module_content_column col_1">
                {this.state.data.cell_1 && (
                <Loader
                  fullWidth={this.state.data.full_width === 1 && this.state.data.layout === 'one'}
                  layout={this.state.data.layout}
                  data={this.state.data.cell_1}
                  brand={this.props.brand}
                  audio={this.props.audio}
                  audioStatus={this.props.audioStatus}
                  audioProgress={this.props.audioProgress}
                  playAudio={this.props.playAudio}
                  pauseAudio={this.props.pauseAudio}
                  openModal={this.props.openModal}
                  zoomImages={this.state.data.gallery_mode}
                  imageVideoTextPosition={this.state.imageVideoTextPosition}
                />
                )}

                {!this.state.data.cell_1 && (
                <div className="empty"></div>
                )}
              </div>

              {this.state.data.layout !== 'one' && (
              <div className="module_content_column col_2">
                {this.state.data.cell_2 && (
                <Loader
                  fullWidth={this.state.data.full_width === 1 && this.state.data.layout === 'one'}
                  layout={this.state.data.layout}
                  brand={this.props.brand}
                  data={this.state.data.cell_2}
                  audio={this.props.audio}
                  audioStatus={this.props.audioStatus}
                  audioProgress={this.props.audioProgress}
                  playAudio={this.props.playAudio}
                  pauseAudio={this.props.pauseAudio}
                  openModal={this.props.openModal}
                  zoomImages={this.state.data.gallery_mode}
                  imageVideoTextPosition={this.state.imageVideoTextPosition}
                />
                )}

                {!this.state.data.cell_2 && (
                <div className="empty"></div>
                )}
              </div>
              )}

              {this.state.data.layout !== 'one' && this.state.data.layout !== 'two' && (
              <div className="module_content_column col_3">
                {this.state.data.cell_3 && (
                <Loader
                  fullWidth={this.state.data.full_width === 1 && this.state.data.layout === 'one'}
                  layout={this.state.data.layout}
                  data={this.state.data.cell_3}
                  brand={this.props.brand}
                  audio={this.props.audio}
                  audioStatus={this.props.audioStatus}
                  audioProgress={this.props.audioProgress}
                  playAudio={this.props.playAudio}
                  pauseAudio={this.props.pauseAudio}
                  openModal={this.props.openModal}
                  zoomImages={this.state.data.gallery_mode}
                  imageVideoTextPosition={this.state.imageVideoTextPosition}
                />
                )}

                {!this.state.data.cell_3 && (
                <div className="empty"></div>
                )}
              </div>
              )}

              {this.state.data.layout === 'four' && (
              <div className="module_content_column col_4">
                {this.state.data.cell_4 && (
                <Loader
                  fullWidth={this.state.data.full_width === 1 && this.state.data.layout === 'one'}
                  data={this.state.data.cell_4}
                  brand={this.props.brand}
                  audio={this.props.audio}
                  audioStatus={this.props.audioStatus}
                  audioProgress={this.props.audioProgress}
                  playAudio={this.props.playAudio}
                  pauseAudio={this.props.pauseAudio}
                  openModal={this.props.openModal}
                  zoomImages={this.state.data.gallery_mode}
                  imageVideoTextPosition={this.state.imageVideoTextPosition}
                />
                )}

                {!this.state.data.cell_4 && (
                <div className="empty"></div>
                )}
              </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Content;
