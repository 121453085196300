import React, { Component } from 'react';


class ColorShareIcon extends Component {
  constructor() {
    super();
  }


  render () {
    return (
      <div className="icon-block-copy-over">
         <svg className="icon-copy" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" >
          <path className="st0" d="M21,24H7c-1.7,0-3-1.3-3-3V7c0-1.7,1.3-3,3-3h14c1.7,0,3,1.3,3,3v14C24,22.7,22.7,24,21,24z M7,6C6.4,6,6,6.4,6,7v14c0,0.6,0.4,1,1,1h14c0.6,0,1-0.4,1-1V7c0-0.6-0.4-1-1-1H7z"/>
            
            <g>
              <path className="st0" d="M17,0H3C1.3,0,0,1.3,0,3v14c0,1.3,0.8,2.4,2,2.8V17v-3.6V3c0-0.6,0.4-1,1-1h10h4h2.8C19.4,0.8,18.3,0,17,0z"/>
            </g>
          </svg>
      </div>
    );
  }
}

export default ColorShareIcon;
