import React, { Component } from 'react';
import './Header.css';

import corebookLogo from 'assets/img/logo-p-500.png';
import mccannLogo from 'assets/img/McCann_Milton Keynes_Logo_White.svg';

import Modal from './Modal';

class Header extends Component {

  render () {
    return (
      <div>
        <div className="showcase-header">
          <div className="corebook-logo">
            <img src={corebookLogo} alt="Corebook" />
            <span>Demo</span>
          </div>

          <div className="partner-logo">
            <img src={mccannLogo} alt="Mccann" />
          </div>

          <div className="try-button">
            <a href="https://content.corebook.io/quickstart" target="_blank" rel="noopener noreferrer">Try Corebook°</a>
          </div>
        </div>

        <Modal />
      </div>
    );
  }
}

export default Header;
