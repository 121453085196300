import React, { Component } from 'react';
import './Login.css';

import LoginScreen from './login/Login';
import RequestScreen from './request/Request';
import UpdateScreen from './update/Update';

class Login extends Component {
  constructor() {
    super();
    this.state = {
      screen : 'login',
    }

    this.switchScreen = this.switchScreen.bind(this);
  }

  componentDidMount() {
    this.setState({
      brand: this.props.brand,
	    page: this.props.page,
      studio: this.props.studio,
      extra: false
    });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if(newProps.brand) {
      this.setState({
        brand: newProps.brand,
        page: this.props.page,
        studio: this.props.studio,
        extra: false
  	  });
    }
  }

  switchScreen = (screen, brand, studio, page, extra = false) => {
		this.setState({
			'screen': screen,
      brand: brand,
      studio: studio,
      page: page,
      extra: extra
		});
	};

  render () {
    return (
      <div>
        {this.state.screen === 'login' && this.state.brand && 
        <LoginScreen brand={this.state.brand} studio={this.state.studio} page={this.state.page} extra={this.state.extra} switchScreen={this.switchScreen} />
        }

        {this.state.screen === 'request' && this.state.brand && 
        <RequestScreen brand={this.state.brand} studio={this.state.studio} page={this.state.page} extra={this.state.extra} switchScreen={this.switchScreen} />
        }

        {this.state.screen === 'update' && this.state.brand && 
        <UpdateScreen brand={this.state.brand} studio={this.state.studio} page={this.state.page} extra={this.state.extra} switchScreen={this.switchScreen} />
        }
      </div>
    );
  }
}

export default Login;
