import React, { Component } from 'react';
import axios from 'axios';
import './Attachments.css';

import {localAnalyticsLogEvent} from 'localAnalytics';

import imgVideo    from 'assets/img/play-video.svg';

import ShareLocation from '../../../partials/share/ShareLocation';

import 'react-photoswipe/lib/photoswipe.css';
import {PhotoSwipe} from 'react-photoswipe';

const api = axios.create();

class Attachments extends Component {

  constructor(props) {
    super(props);
    this.state = {
      item: false,
      images: [],
      visible: false,
      isGallery: false,
      gallery: [],
      galleryOpen: false,
      galleryIndex: 0,
      brand: false,
      downloadInterval: '',
      downloadProcess: false,
      modules: [],
      backgroundColour: '#fff',
      brandBackgroundColor: '#fff',
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
      if(newProps.item){
        var elementsArray = document.getElementsByClassName("container-full");
        this.setState({
          item: newProps.item,
          images: newProps.gallery,
          galleryIndex: newProps.index,
          visible: newProps.show,
          showcase: elementsArray.length > 0 ? elementsArray[0].className.indexOf("showcase") !== -1 : false,
          brand: newProps.brand,
          modules: newProps.modules,
          brandBackgroundColor: newProps.brandBackgroundColor,
        });

        if (newProps.item.id) {
          this.setBackgroundColor(newProps);
        }
      }
  }

  setBackgroundColor = (props) => {
    let module_id = props.item.content_module_id ? props.item.content_module_id : props.item.module_id;

    let module = props.modules.find(obj => {
      return obj.id === module_id;
    });

    if (module) {
      this.setState({
        backgroundColour: module.background == 'transparent' ? props.brandBackgroundColor : module.background
      })
    }
  }

  itemHasDownloads = (item) => {
    return item.attachments && item.attachments.length > 0;
  }

  hideImageModal = () => {
    this.props.closeModal();
  }

  openPhotoSwipe = (index) => {
    this.setState({
      galleryIndex: index,
      galleryOpen: true,
      gallery: [{src:this.state.item.image_1600, w:this.state.item.w ? this.state.item.w : 1500, h: this.state.item.h ? this.state.item.h : 1500}],
    });
  };

  handleClose = () => {
    this.setState({
      galleryOpen: false,
      galleryIndex: 0,
      gallery: []
    });
  };

  // Triger right mouse click in showcase/modal
  openModal = (e) => {
    e.preventDefault();
    if (window.CustomEvent) {
      window.dispatchEvent(new CustomEvent('contextmenu'));
    } else if (document.createEvent) {
      var ev = document.createEvent('HTMLEvents');
      ev.initEvent('contextmenu', true, false);
      window.dispatchEvent(ev);
    } else {
      window.fireEvent('oncontextmenu');
    }
  };


  // Prepare Dash asset download
  createDashDownload = (attachment) => {
    if (this.state.downloadProcess) return false;

    this.setState({ downloadProcess: attachment.id });

    let self = this;
    let asset_id = attachment.filename.split('file=');

    asset_id = asset_id[1] ? asset_id[1] : asset_id[0];

    api
      .get(window.API + this.state.brand + '?action=create-dash-asset&id=item&asset_id=' + asset_id, {
        headers: {
          Pragma: 'no-cache',
          Authorization: localStorage.getItem('authToken') || ''
        }
      }).then(function (response) {
        let intervalId = setInterval(function(){
          self.getDashDownload(response.data.id);
        }, 1000);

        self.setState({ downloadInterval: intervalId })
      });
  };

  getDashDownload = async (id) => {
    try {
      const response = await api.get(window.API + this.state.brand + '?action=get-dash-asset&id=' + id, {
        headers: {
          Pragma: 'no-cache',
          Authorization: localStorage.getItem('authToken') || ''
        }
      });

      if (response.data.type === 'COMPLETED') {
        clearInterval(this.state.downloadInterval);
        this.setState({ downloadProcess: false });
        window.location.href = response.data.result.successes.item.result.url;
      }
    } catch (err) {
      console.error(err);
    }
  };

  createAssetbankDownload = (attachment) => {
    if (this.state.downloadProcess) return false;

    this.setState({ downloadProcess: attachment.id });

    let self = this;
    let asset_id = attachment.filename;

    api
      .get(window.API + this.state.brand + '?action=get-assetbank-asset&id=item&id=' + asset_id, {
        headers: {
          Pragma: 'no-cache',
          Authorization: localStorage.getItem('authToken') || ''
        }
      }).then(function (response) {
        self.setState({ downloadProcess: false });
        window.location.href = response.data.url;
      });
  };

  trackDownloadEvent = (token) => {
    const d = new Date();
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    let item = this.state.item;
    let index = item.attachments.findIndex(object => {
      return object.token === token;
    });

    item.attachments[index].downloaded = monthNames[d.getMonth()] + ' ' + d.getDate() + ', ' + d.getFullYear();

    this.setState({
      item: item
    });

    localAnalyticsLogEvent("public_item_attachment_downloaded", {brandId: window.loadedBrandId, label: token});
  }

  render () {
    const { galleryOpen, galleryIndex, gallery } = this.state;

    return (
      <div>
        <div className={'modal-overlay' + (this.state.visible ? ' visible' : '')} onClick={() => { this.hideImageModal() }}></div>
        <div className={'modal-image withbg' + (this.state.visible ? ' visible' : '')}  style={{backgroundColor:this.state.backgroundColour}}>
          <ShareLocation module={this.state.item.content_module_id ? this.state.item.content_module_id : this.state.item.module_id} popup={this.state.item.id} />

            <span className="close" onClick={() => { this.hideImageModal() }}></span>

            {this.state.item.image && (
            <div className="image" onClick={() => { this.openPhotoSwipe(galleryIndex) }}>
              <img src={this.state.item.image} style={{ backgroundColor:this.state.item.background, borderRadius:this.state.item.border_radius, border:this.state.item.border_width+'px solid '+this.state.item.border_color }} alt=""/>
            </div>
            )}

            {this.state.item.module === 'audio' && (
            <div className="modal-image_play">
              <div className="play">
                  <svg id="play-audio" xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20"><g><g clipPath="url(#clip-DE63A36E-BBC3-49A8-B9CF-3677ED9EDA6E)"><path fill="#0c56ff" d="M18.976 15.753c.1.869-.114 1.75-.601 2.479-1.113 1.635-3.315 2.057-4.916.92a3.661 3.661 0 0 1-.901-5.022c1.113-1.635 3.315-2.056 4.917-.92V6.607L7.04 8.764v5.61a3.67 3.67 0 0 1-.638 2.07 3.48 3.48 0 0 1-4.904.88C-.09 16.188-.478 13.938.636 12.316c1.113-1.622 3.315-2.018 4.904-.881V3.961c0-.946.65-1.764 1.563-1.955L16.636.038C16.761.013 16.9 0 17.024 0c1.076 0 1.952.895 1.952 1.993zm-15.463.703c1.114 0 2.015-.933 2.027-2.07 0-1.15-.913-2.07-2.027-2.07-1.126 0-2.026.92-2.026 2.07s.913 2.07 2.026 2.07zM17.475 5.053V1.981c0-.026-.013-.064-.013-.09a.458.458 0 0 0-.538-.357l-9.52 1.97a.475.475 0 0 0-.363.461v3.263zm-2.002 13.204c.087 0 .162-.013.237-.025 1.115-.127 1.902-1.15 1.777-2.287h-.012v-.077c-.15-1.01-1-1.75-2.002-1.75-1.126 0-2.026.92-2.026 2.07s.913 2.07 2.026 2.07z"/></g></g></svg>
              </div>
            </div>
            )}

            {this.state.item.module === 'video' && (
            <div className="modal-image_play">
              <div className="play">
                  <img src={imgVideo} alt="" />
              </div>
            </div>
            )}

            {this.state.item.module === 'videotext' && (
            <div className="modal-image_play">
              <div className="play">
                  <img src={imgVideo} alt="" />
              </div>
            </div>
            )}

            {this.itemHasDownloads(this.state.item) && (
            <ul>
              {this.state.item.attachments.map(attachment =>
                <li key={attachment.id}>
                  {this.state.showcase && (
                  <a href="#/" onClick={this.openModal}>
                    <div className="attachment-item">
                      <span>{attachment.extension}</span>
                    </div>
                    <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><defs><path id="wee3a" d="M1468 2620v4a2 2 0 0 1-2 2h-14a2 2 0 0 1-2-2v-4"/><path id="wee3b" d="M1454 2615l5 5 5-5"/><path id="wee3c" d="M1459 2620v-12"/></defs><g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3a"/></g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3b"/></g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3c"/></g></g></svg>
                  </a>
                  )}

                  {!this.state.showcase && attachment.source !== 'dash' && attachment.source !== 'dropbox' && attachment.source !== 'google' && (
                  <a href={attachment.filename} download="true" onClick={() => { this.trackDownloadEvent(attachment.token) }}>
                    <div className="attachment-item">
                      <span>{attachment.extension}</span>
                    </div>
                    <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><defs><path id="wee3a" d="M1468 2620v4a2 2 0 0 1-2 2h-14a2 2 0 0 1-2-2v-4"/><path id="wee3b" d="M1454 2615l5 5 5-5"/><path id="wee3c" d="M1459 2620v-12"/></defs><g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3a"/></g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3b"/></g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3c"/></g></g></svg>
                  </a>
                  )}

                  {!this.state.showcase && attachment.source === 'dash' && (
                  <a href="#/" onClick={(e) => { e.preventDefault(); this.createDashDownload(attachment); this.trackDownloadEvent(attachment.token) }}>
                    <div className="attachment-item">
                      <span>{attachment.extension}</span>
                    </div>

                    {this.state.downloadProcess === attachment.id && (
                      <div className="spinner-icon spinner-icon--20"></div>
                    )}

                    {this.state.downloadProcess !== attachment.id && (
                      <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><defs><path id="wee3a" d="M1468 2620v4a2 2 0 0 1-2 2h-14a2 2 0 0 1-2-2v-4"/><path id="wee3b" d="M1454 2615l5 5 5-5"/><path id="wee3c" d="M1459 2620v-12"/></defs><g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3a"/></g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3b"/></g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3c"/></g></g></svg>
                    )}
                  </a>
                  )}

                  {!this.state.showcase && attachment.source === 'dropbox' && (
                  <a href={attachment.filename} onClick={() => { this.trackDownloadEvent(attachment.token) }} target={'_blank'} rel="noopener noreferrer">
                    <div className="attachment-item">
                      <span>{attachment.extension}</span>
                    </div>
                    <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><defs><path id="wee3a" d="M1468 2620v4a2 2 0 0 1-2 2h-14a2 2 0 0 1-2-2v-4"/><path id="wee3b" d="M1454 2615l5 5 5-5"/><path id="wee3c" d="M1459 2620v-12"/></defs><g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3a"/></g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3b"/></g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3c"/></g></g></svg>
                  </a>
                  )}

                  {!this.state.showcase && attachment.source === 'google' && (
                  <a href={attachment.filename} onClick={() => { this.trackDownloadEvent(attachment.token) }} target={'_blank'} rel="noopener noreferrer">
                    <div className="attachment-item">
                      <span>{attachment.extension}</span>
                    </div>
                    <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><defs><path id="wee3a" d="M1468 2620v4a2 2 0 0 1-2 2h-14a2 2 0 0 1-2-2v-4"/><path id="wee3b" d="M1454 2615l5 5 5-5"/><path id="wee3c" d="M1459 2620v-12"/></defs><g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3a"/></g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3b"/></g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3c"/></g></g></svg>
                  </a>
                  )}

                  <div className="file-info">
                    <div className="file-info__title">{attachment.preview_title}</div>
                    <div className="file-info__details">
                        <p>
                          Kind: {attachment.type}<br/>Size: {attachment.size !== 0 ? attachment.size : 'Unknown'}
                          {attachment.size_x && attachment.size_y && (
                          <span><br/>Dimensions: {attachment.size_x} x {attachment.size_y}</span>
                          )}
                        </p>
                        <p>
                          Uploaded: {attachment.uploaded}<br/>Last downloaded: {attachment.downloaded}
                        </p>
                        {attachment.preview && (
                        <p className="preview">
                          <img src={attachment.preview} alt=""/>
                        </p>
                        )}
                    </div>
                  </div>
                </li>
              )}
            </ul>
            )}
        </div>

        <PhotoSwipe
          isOpen={galleryOpen}
          items={gallery}
          options={{
            index: galleryIndex,
            captionEl: false,
            shareEl: false,
            closeOnScroll: true,
            zoomEl: false,
            history: false
          }}
          onClose={this.handleClose}
        />
      </div>
    );
  }
}

export default Attachments;
